// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'shop'
  }, {
    prop: 'name'
  }, {
    prop: 'lat'
  }, {
    prop: 'lon'
  }, {
    prop: 'operation_hours'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'phone_number'
  }, {
    prop: 'poiable_type'
  }, {
    prop: 'is_shipping_point',
    back: true
  }, {
    prop: 'support_pickup',
    back: true
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'deleted_at'
  }]
}
